import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import Markdown from 'preact-markdown';
import { QRCodeSVG } from 'qrcode.react';
import md5 from 'crypto-js/md5'
import { route } from 'preact-router';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import FastList from '../../components/fastlist';
import FormFields from '../../components/form/fields';
import Input from '../../components/form/input';
import ImageScroller from '../../components/imagescroller';
import InspectionStatus from '../../components/inspection/status';
import FileList from '../../components/fileViewer/list';
import GradientRight from '../../components/gui/gradientRight';

const scrollDistance = 210;
const scrollDistanceSnap = 90;
const activationRange = 20;

const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') {
        dir = -1;
        o = o.substring(1);
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);

@withText(props => ({
    orderNumber: <Text id='input.orderNumber-help'>orderNumber</Text>,
    name: <Text id='input.name-help'>name</Text>,
}))
@observer
class GuiList extends Component {
  	constructor(props) {
        super(props);
        this.state = {
            focusedIndex: 0,
        };
    }

    viewElement = (e, props) => {
        e.stopPropagation();
        e.preventDefault();
        const { swipeIdOpen } = this.state;
        const { id } = props ? props?.id : e?.target.closest('.box-line').dataset;
        const objId = parseInt(id, 10);
        if (swipeIdOpen === objId) {
            this.closeSwipMenu(e);
            return;
        }
        const { view = () => {} } = this.props;
        view(e, props);
    }

    deleteElement = e => {
        const { deleteElement = () => {} } = this.props;
        deleteElement(e);
        this.closeSwipMenu(e);
    }

    rentOutElement = e => {
        const { rentOutElement = () => {} } = this.props;
        rentOutElement(e);
        this.closeSwipMenu(e);
    }

    moveElement = e => {
        const { moveElement = () => {} } = this.props;
        moveElement(e);
        this.closeSwipMenu(e);
    }

    addUsageData = e => {
        const { addUsageData = () => {} } = this.props;
        addUsageData(e);
        this.closeSwipMenu(e);
    }

    checkElement = e => {
        const { checkElement = () => {} } = this.props;
        checkElement(e);
        this.closeSwipMenu(e);
    }

    editElement = e => {
        const { editElement = () => {} } = this.props;
        editElement(e);
        this.closeSwipMenu(e);
    }

    swipeLeft1Func = e => {
        const { swipeLeft1Func = () => {} } = this.props;
        swipeLeft1Func(e);
        this.closeSwipMenu(e);
    }

    swipeLeft2Func = e => {
        const { swipeLeft2Func = () => {} } = this.props;
        swipeLeft2Func(e);
        this.closeSwipMenu(e);
    }

    swipeRight1Func = e => {
        const { swipeRight1Func = () => {} } = this.props;
        swipeRight1Func(e);
        this.closeSwipMenu(e);
    }

    swipeRight2Func = e => {
        const { swipeRight2Func = () => {} } = this.props;
        swipeRight2Func(e);
        this.closeSwipMenu(e);
    }

    refreshElement = async e => {
        const { refreshElement = () => {} } = this.props;
        this.setState({ refreshElementInProgress: true });
        await refreshElement(e);
        this.setState({ refreshElementInProgress: false });
        this.closeSwipMenu(e);
    }

    handleIntersection = (entries) => {
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.intersectionObserverCallback(entries);
        }
    }

    // Swipe shit
    touchStart = (e) => {
        const { id } = e.target.closest('.box-line').dataset;
        const { isSwipedOpen } = this.state;
        this.setState({
            swipeId: parseInt(id, 10),
            startY: e.touches[0].pageY,
            startX: e.touches[0].pageX,
            isSwipedOpen: false,
            diffY: undefined,
            diffX: undefined,
            // absDiffX: undefined,
            deleteVerify: false,
        });
    }

    touchEnd = (e) => {
        const { isSwipedOpen, absDiffX } = this.state;
        if (isSwipedOpen) {
            this.setState({
                startY: undefined,
                startX: undefined,
            });
        } else if (absDiffX >= scrollDistanceSnap) {
            const { id } = e.target.closest('.box-line').dataset;
            const swipeId = parseInt(id, 10);
            this.setState({
                isSwipedOpen: true,
                swipeIdOpen: swipeId,
                absDiffX: scrollDistance,
            });
        } else if (absDiffX >= activationRange) {
            this.setState({
                startY: undefined,
                startX: undefined,
                isSwipedOpen: false,
                deleteVerify: false,
                diffY: 1,
                diffX: 1,
                absDiffX: 1,
            });
            setTimeout(() => {
                this.setState({
                    swipeIdOpen: undefined,
                    diffY: undefined,
                    diffX: undefined,
                    absDiffX: undefined,
                });
            }, 400);
        }
    }

    touchMove = (e) => {
        // e.stopPropagation(); // This should be removed to allow pull to refresh when on top of page.
        const { isSwipedOpen } = this.state;
        if (isSwipedOpen) {
            return false;
        }
        const { startY, startX } = this.state;
        const y = e.touches[0].pageY;
        const x = e.touches[0].pageX;
        const diffY = y - startY;
        const absDiffY = Math.abs(diffY);
        let diffX = x - startX;
        let absDiffX = Math.abs(diffX);
        let swipeDirection = '';

        if ( absDiffX < absDiffY ) { /*most significant*/
            return false;
        }

        if (absDiffX < activationRange) {
            return true;
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
        if (diffX > 0) {
            swipeDirection = 'right';
        } else if (diffX < 0) {
            swipeDirection = 'left';
        }
        if (absDiffX >= scrollDistance) {
            absDiffX = scrollDistance;
        }
        // console.log({ absDiffX, scrollDistance, swipeDirection });
        this.setState({
            diffY,
            diffX,
            absDiffX,
            swipeDirection,
        });

        if (y > (startY + scrollDistance)) {
            // console.log('swiping down', y, '>', startY + scrollDistance);
        } else if (y < (startY - scrollDistance)) {
            // console.log('swiping up', y, '<', startY + scrollDistance);
        }

        if (absDiffX >= scrollDistance) {
            const { id } = e.target.closest('.box-line').dataset;
            const swipeId = parseInt(id, 10);
            this.setState({
                isSwipedOpen: true,
                swipeIdOpen: swipeId,
            });
        }
    }

    closeSwipMenu = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            diffY: 1,
            diffX: 1,
            absDiffX: 1,
        });
        setTimeout(() => {
            this.setState({
                isSwipedOpen: false,
                swipeIdOpen: undefined,
                diffY: undefined,
                diffX: undefined,
                absDiffX: undefined,
                deleteVerify: false,
            });
        }, 400);
    }
    // /Swipe shit

    clearSearch = () => {
        this.setState({
            search: '',
        });
    }

    searchInput = (e) => {
        const search = e.target.value;
        const { holdingStore, searchCallback = () => {} } = this.props;
        this.setState({ search });
        searchCallback(search);
    }

    componentDidMount() {
        // this.setLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            this.scrollViewTimer = setInterval(() => holdingStore.postScrollview(), 30 * 1000);
        }
    }
    componentWillUnmount() {
        // this.unsetLoadMore();
        const { holdingStore } = this.props;
        if (holdingStore) {
            holdingStore.postScrollview()
            clearInterval(this.scrollViewTimer);
        }
    }

    handleKeyDown = (e) => {
        const { search, focusedIndex } = this.state;
        const {
            list = [],
            sortBy = [],
            sortTitle = '',
        } = this.props;

        let finalList = list.filter(el => {
            if (!search) {
                return true;
            }
            const searchLower = search.toLowerCase();
            const name = el.name?.toLowerCase() || el.client?.toLowerCase() || '';
            const description = el.description?.toLowerCase() || '';
            const tags = el.tags ? el.tags.join(' ').toLowerCase() : [];
            return name.includes(searchLower) || description.includes(searchLower) || tags.includes(searchLower);
        });
        const listLength = finalList.length;

        if (sortBy.length > 0) {
            finalList = finalList.sort(fieldSorter([sortTitle]));
        }

        if (e.key === 'ArrowDown') {
            e.preventDefault();
            const nextIndex = (focusedIndex + 1) % listLength;
            this.setState({
                focusStarted: true,
                focusedIndex: nextIndex,
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            const prevIndex = (focusedIndex - 1 + listLength) % listLength;
            this.setState({
                focusStarted: true,
                focusedIndex: prevIndex,
             });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            const obj = finalList[focusedIndex];
            const viewElement = obj.viewElement || this.viewElement;
            viewElement(null, {
                id: obj.id,
            });
        }
    }

    renderContent = ({ obj, idx }) => {
        const {
            title = '',
            list = [],
            classNames = '',
            qty,
            qtyStyle = '',
            showQty = true,
            elHeader,
            elIcon,
            elIconFunc,
            elTitle = e => e.title,
            elLink,
            elImageStatus = e => '',
            elDescription = e => '',
            elInfo1 = e => '',
            elInfo2 = e => '',
            elInfo3 = e => '',
            viewTags,
            inc,
            dec,
            remove,
            editQty,
            wrapperProps = {},
            wrapperPropsFunc = () => {},
            deleteElement,
            rentOutElement,
            moveElement,
            checkElement,
            addUsageData,
            editElement,

            swipeLeft1Icon,
            swipeLeft1IconFunc,
            swipeLeft1Class = 'secondary',
            swipeLeft1ClassFunc,
            swipeLeft1Title,
            swipeLeft1TitleFunc,
            swipeLeft1Func,

            swipeLeft2Icon,
            swipeLeft2IconFunc,
            swipeLeft2Class = 'secondary',
            swipeLeft2ClassFunc,
            swipeLeft2Title,
            swipeLeft2TitleFunc,
            swipeLeft2Func,

            swipeRight1Icon,
            swipeRight1Class = 'secondary',
            swipeRight1Title,
            swipeRight1Func,

            swipeRight2Icon,
            swipeRight2Class = 'secondary',
            swipeRight2Title,
            swipeRight2Func,

            refreshElement,
            showCheckElement = () => true,
            showIncDec = () => true,
            showEmptyImage = true,
            showCevron = false,
            showRightIcon,
            rightBoxField = '',
            rightBoxFieldValue = e => e,
            rightBoxFieldPostfix = '',
            rightBoxFieldPostfixField,
            rightBoxFieldPostfixValue,
            rightBoxFieldBottomValue,

            rightBoxTitle,
            rightBoxValue = () => '',
            rightBoxClass = () => '',

            rightFunc,

            imgHeight,
            holdingStore,
        } = this.props;
        const { appState } = this.props.stores;
        const { opts = {} } = appState;

        const { absDiffX, swipeId, swipeDirection, refreshElementInProgress, focusedIndex, focusStarted } = this.state;
        const swipeMenuIsOpen = absDiffX > 0;

        const hasSwipeLeftFunctions = (deleteElement || rentOutElement || moveElement || checkElement || addUsageData || swipeLeft1Func || swipeLeft2Func);
        const hasSwipeRightFunctions = (editElement || swipeRight1Func || swipeRight2Func);
        const hasSwipeFunctions = (hasSwipeLeftFunctions || hasSwipeRightFunctions);

        const hasVideoStream = obj.files?.some(file => file.s3LinkStream);
        const videoStreamPreview = obj.files?.find(file => file.s3LinkStream)?.s3LinkStreamPreview1;

        let isElementSwipedOpen = false;
        if (swipeDirection === 'left' && opts.swipeLeft) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeLeftFunctions
            );
        } else if (swipeDirection === 'right' && opts.swipeRight) {
            isElementSwipedOpen = (
                swipeId === obj.id &&
                swipeMenuIsOpen &&
                hasSwipeRightFunctions
            );
        }
        return (<>
            <div class={`w-100 d-flex flex-row position-relative`}>
                <div
                    class={`w-100 flex-fill d-flex flex-row justify-content-between box-line overflow-hidden position-relative ${obj.classNames || ''} ${(focusStarted && focusedIndex === idx) ? 'box-line-focused' : ''}`}
                    onClick={obj.viewElement || this.viewElement}
                    data-isopen={swipeMenuIsOpen}
                    data-id={obj.id}
                    data-title={elTitle(obj)}
                    data-type={holdingStore?.name}
                    {...wrapperProps}
                    {...wrapperPropsFunc(obj)}
                    onTouchstart={hasSwipeFunctions && this.touchStart}
                    onTouchend={hasSwipeFunctions && this.touchEnd}
                    onTouchmove={hasSwipeFunctions && this.touchMove}
                    style={`
                        ${obj.styles || ''}
                        ${isElementSwipedOpen && `
                            overflow: hidden;
                            left: ${swipeDirection === 'left' ? `-` : ''}${absDiffX || 0}px !important;
                        `}
                    `}
                >
                    {/* swipeIdOpen: {swipeIdOpen} */}
                    {/* swipeId: {swipeId} */}
                    {/* diffX: {diffX} */}
                    {/* isSwipedOpen: {JSON.stringify(isSwipedOpen, null, 4)} */}
                    {/* isSwipedOpen: {JSON.stringify({ diffX, isSwipedOpen, swipeId}, null, 4)} */}
                    {elHeader && <div class='box-line-header position-absolute box-line-info' style='top: -2px; right: 110px;'>
                        {elHeader(obj)}
                    </div>}
                    {qty ? <>
                        <div class='box-line-qty text-right' style={qtyStyle}>{qty(obj)}</div>
                    </> : <>
                        {showQty && <>
                            {util.isDefined(obj.qty) && <div
                                class='box-line-qty text-right'
                                style={qtyStyle}
                                onClick={opts.allowEditQty ? editQty : () => {}}
                                data-id={obj.id}
                                data-qty={obj.qty}
                            >
                                {obj.qty}
                            </div>}
                        </>}
                    </>}
                    {obj.icon && <>
                        <div class='box-line-icon mt-2 ml-3' style='font-size: 1.7em; min-width: 25px; width: 25px;'>
                            <i class={obj.icon} />
                        </div>
                    </>}
                    {elIcon && <>
                        <div class='d-flex flex-row justify-content-center align-items-center box-line-icon mt-2 ml-2' style='font-size: 1.7em; min-width: 25px; width: 25px;'>
                            <i class={elIcon(obj)} />
                        </div>
                    </>}
                    {elIconFunc && <>
                        <div class='d-flex flex-row justify-content-center align-items-center box-line-icon mt-0 ml-2' style='font-size: 1.7em; min-width: 35px; width: 35px;'>
                            {elIconFunc(obj)}
                        </div>
                    </>}
                    <div class='flex-fill overflow-hidden d-flex flex-column justify-content-center'>
                        <div class='w-100 d-flex flex-row justify-content-between align-items-center pl-3 position-relative'>
                            <GradientRight stores={this.props.stores} />
                            <div class='box-line-title '>
                                {elLink ? <>
                                    <a href={elLink(obj)} class='text-dark'>
                                        {elTitle(obj)}
                                    </a>
                                </> : <>
                                    {elTitle(obj)}
                                </>}
                                {elDescription(obj) && <div class='box-line-title-info'>
                                    {elDescription(obj)}
                                </div>}
                            </div>
                        </div>
                        <div class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 position-relative'>
                            <GradientRight stores={this.props.stores} />
                            <div class='box-line-info-item'>
                                {elInfo1(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo2(obj)}
                            </div>
                            <div class='box-line-info-item'>
                                {elInfo3(obj)}
                            </div>
                            {/* <div class='box-line-info-item'>
                                <FileList files={obj.files} stores={this.props.stores} />
                            </div> */}
                            {/* <div class='box-line-info-item'>
                                <span class='mr-2'>
                                    <i class='fa-solid fa-magnifying-glass' />
                                </span>
                                {obj.inspections.length}
                            </div> */}
                        </div>
                        <div class='box-line-info d-flex flex-row justify-content-between px-3 pb-0 position-relative'>
                            <GradientRight stores={this.props.stores} />
                            {viewTags && <>
                                {viewTags(obj)}
                            </>}
                        </div>
                    </div>
                    {!!obj[rightBoxField] && rightBoxFieldValue(obj[rightBoxField]) && <>
                        <div
                            class='box-line-right-box mt-1 mb-1 mr-2 py-1 px-1 text-muted bg-light rounded-lg d-flex flex-column justify-content-center align-items-center flex-wrap text-center'
                            style='width: 75px !important; min-width: 75px !important; line-height: 1.0em;'
                        >
                            <nobr><span class='font-weight-bold'>{rightBoxFieldValue(obj[rightBoxField])}</span></nobr>
                            <small class='text-muted'>{rightBoxFieldPostfixValue ? rightBoxFieldPostfixValue(obj[rightBoxFieldPostfixField]) : rightBoxFieldPostfix}</small>
                            {rightBoxFieldBottomValue && <>
                                <small class='text-muted font-weight-lighter'>{rightBoxFieldBottomValue(obj)}</small>
                            </>}
                        </div>
                    </>}
                    {rightBoxTitle && <>
                        <div
                            class={`box-line-right-box mt-1 mb-1 mr-2 py-1  rounded-lg d-flex flex-column justify-content-center align-items-center flex-wrap text-center
                                ${rightBoxClass && rightBoxClass(obj) ? rightBoxClass(obj) : 'text-muted bg-light'}`}
                            style='width: 60px; line-height: 1.0em;'
                        >
                            <span class='font-weight-bold'>{rightBoxValue(obj)}</span>
                            <small>{rightBoxTitle}</small>
                        </div>
                    </>}
                    {rightFunc && <>
                        {rightFunc(obj)}
                    </>}
                    {obj.images && (showEmptyImage || obj.images[0] || obj.imageUrl) && <div class='box-line-image position-relative' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`}>
                        {obj.images[0] ? <>
                            {elLink ? <>
                                <a href={elLink(obj)} class='text-dark'>
                                    <img src={`${obj.images[0].s3MediumLink}`} class='rounded-lg' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                                </a>
                            </> : <>
                                <img src={`${obj.images[0].s3MediumLink}`} class='rounded-lg' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                            </>}
                        </> : <>
                            {obj.imageUrl ? <>
                                <img src={`${obj.imageUrl}`} class='rounded-lg' style={`${imgHeight ? `height: ${imgHeight} !important;` : ''}`} />
                            </> : <>
                                <div
                                    class='box-line-image-placeholder border h-100 rounded-lg d-flex justify-content-center align-items-center text-muted'
                                    style={`
                                        font-size: 2.0em;
                                        ${videoStreamPreview ? '' : 'opacity: 0.6;'}
                                        background-image: url(${videoStreamPreview});
                                        background-size: cover;
                                        background-position: center;
                                        background-repeat: no-repeat;
                                    `}
                                >
                                    {hasVideoStream ? <>
                                        <i class='fa-solid fa-circle-play' />
                                    </> : <>
                                        <i class='fa-duotone fa-camera-retro' />
                                    </>}
                                </div>
                            </>}
                        </>}
                        {opts.showInspections && <div class='box-line-status position-absolute' style='top: 5px; right: 5px;'>
                            {elImageStatus(obj)}
                        </div>}
                        {opts.allowEditQty && showIncDec(obj) && inc && <>
                            <button type='button' class='btn btn-lg btn-success px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em; bottom: 0px; right: 0px;' onClick={inc} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-plus' /></button>
                        </>}
                        {opts.allowEditQty && showIncDec(obj) && dec && <>
                            <button type='button' class='btn btn-lg btn-danger px-1 py-0 position-absolute rounded-pill-both' style='font-size: 1.4em;bottom: 0px; left: 0px;' onClick={dec} data-id={obj.id} data-qty={obj.qty}><i class='fa-solid fa-minus' /></button>
                        </>}
                    </div>}

                    {showCevron && <>
                        <div class='box-line-chevron mt-3 mr-3 text-muted'>
                            <i class='fa-solid fa-chevron-right' />
                        </div>
                    </>}
                    {showRightIcon && <>
                        <div class='box-line-icon mt-3 mr-3'>
                            <i class={showRightIcon(obj)} />
                        </div>
                    </>}
                    {/* {remove && <>
                        <button type='button' class='btn btn-lg btn-link' style='font-size: 1.5em;' onClick={remove} data-id={obj.id}><i class='fa-solid fa-trash' /></button>
                    </>} */}
                </div>
                {isElementSwipedOpen && <>
                    <div
                        class={`d-flex h-100 flex-row ${swipeDirection === 'left' ? 'pl-0' : 'pr-0'} bg-light position-absolute swipe-container`}
                        style={`
                            overflow: hidden;
                            width: ${absDiffX || 0}px !important;
                            min-width: ${absDiffX || 0}px !important;
                            transition: 0.4s ease-out;
                            ${swipeDirection === 'left' ? 'right: 0px;' : 'left: 0px;'}
                            top: 0px;
                        `}
                        onClick={this.closeSwipMenu}
                    >
                        {swipeDirection === 'left' ? <>
                            {opts.showLoan && rentOutElement && <div
                                class='bg-success text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                onClick={this.rentOutElement}
                            >
                                <i class='fa-solid fa-truck-ramp-box' />
                                <small>
                                    <Text id='list.add-loan'>Loan</Text>
                                </small>
                            </div>}
                            {opts.showAddUsage && addUsageData && <div
                                class='bg-warning text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.addUsageData}
                            >
                                <i class='fa-light fa-clock' />
                                <small>
                                    <Text id='list.add-usage'>Usage</Text>
                                </small>
                            </div>}
                            {opts.showMove && moveElement && <div
                                class='bg-primary text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.moveElement}
                            >
                                <i class='fa-light fa-house-circle-check' />
                                <small>
                                    <Text id='list.move'>Move</Text>
                                </small>
                            </div>}
                            {opts.showDelete && deleteElement && <div
                                class='bg-danger text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                onClick={this.deleteElement}
                            >
                                <i class='fa-solid fa-trash' />
                                <small>
                                    <Text id='list.delete'>Delete</Text>
                                </small>
                            </div>}

                            {swipeLeft1Func && <div
                                class={`bg-${swipeLeft1ClassFunc ? swipeLeft1ClassFunc(obj) : swipeLeft1Class} text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button`}
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.swipeLeft1Func}
                            >
                                <i class={swipeLeft1IconFunc ? swipeLeft1IconFunc(obj) : swipeLeft1Icon} />
                                <small>
                                    {swipeLeft1TitleFunc ? swipeLeft1TitleFunc(obj) : swipeLeft1Title}
                                </small>
                            </div>}
                            {swipeLeft2Func && <div
                                class={`bg-${swipeLeft2ClassFunc ? swipeLeft2ClassFunc(obj) : swipeLeft2Class} text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button`}
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.swipeLeft2Func}
                            >
                                <i class={swipeLeft2IconFunc ? swipeLeft2IconFunc(obj) : swipeLeft2Icon} />
                                <small>
                                    {swipeLeft2TitleFunc ? swipeLeft2TitleFunc(obj) : swipeLeft2Title}
                                </small>
                            </div>}

                            {checkElement && <>
                                {showCheckElement(obj) && <>
                                    <div
                                        class='bg-success text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                        // style='min-width: 44px !important;'
                                        data-id={obj.id}
                                        onClick={this.checkElement}
                                    >
                                        <i class='fa-solid fa-check' />
                                        <small>
                                            <Text id='list.return'>Return</Text>
                                        </small>
                                    </div>
                                </>}
                            </>}
                        </> : <>
                            {swipeRight1Func && <div
                                class={`bg-${swipeRight1Class} text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button`}
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.swipeRight1Func}
                            >
                                <i class={swipeRight1Icon} />
                                <small>
                                    {swipeRight1Title}
                                </small>
                            </div>}
                            {swipeRight2Func && <div
                                class={`bg-${swipeRight2Class} text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button`}
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.swipeRight2Func}
                            >
                                <i class={swipeRight2Icon} />
                                <small>
                                    {swipeRight2Title}
                                </small>
                            </div>}
                            {opts.showEdit && editElement && <div
                                class='bg-secondary text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.editElement}
                            >
                                <i class='fa-light fa-pen' />
                                <small>
                                    <Text id='list.edit-element'>Edit</Text>
                                </small>
                            </div>}
                            {opts.showRefresh && refreshElement && obj.url && <div
                                class='bg-info text-white d-flex flex-column justify-content-center align-items-center flex-fill list-button'
                                // style='min-width: 44px !important;'
                                data-id={obj.id}
                                data-md5={obj.md5}
                                onClick={this.refreshElement}
                            >
                                <i class={`fa-light fa-refresh ${refreshElementInProgress ? 'fa-arrows-rotate fa-spin' : 'fa-refresh'}`} />
                                <small>
                                    <Text id='list.refresh-element'>Refresh</Text>
                                </small>
                            </div>}
                        </>}
                    </div>
                </>}
            </div>
        </>);
    }

    searchFilter = (el) => {
        const { search } = this.state;

        const searchFields = ['name', 'title', 'firstname', 'lastname', 'sportsClub',
        'email', 'phone', 'address', 'city', 'zip', 'country', 'description', 'tags'];
        if (!search) {
            return true;
        }
        const searchLower = search.toLowerCase();
        for (let i = 0; i < searchFields.length; i += 1) {
            const field = searchFields[i];
            if (el[field] && `${el[field]}`.toLowerCase().includes(searchLower)) {
                return true;
            }
        }
        return false;
    }

    render() {
        const { search, focusedIndex } = this.state;
        const { appState, userStore } = this.props.stores;
        const { isDevelopment, path, apiServer } = appState;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const {
            category = '',
            title = '',
            rightButton,
            list = [],
            classNames = '',
            styles = '',
            classNamesList = 'mx-3',
            helpTextClassNames = '',
            hideEmpty = false,
            addItem,
            filter,
            sortTitle = '',
            sortBy = [],
            sortFunc = () => {},
            showFilterBox = true,
            toggle,
            toggleTitle,
            toggleFunc,
            help,
            holdingStore,
            children,
        } = this.props;

        if (hideEmpty && list.length === 0) {
            return (<></>);
        }

        let finalList = Array.isArray(list) ? list.filter(this.searchFilter) : [];

        if (sortBy.length > 0) {
            finalList = finalList.sort(fieldSorter([sortTitle]));
        }

        const sortByField = sortTitle.replace(/^-/, '');
        const sortByFieldObj = fields.getField('part', sortByField);

        const sortDir = sortTitle[0] === '-' ? 'desc' : 'asc';

        return (<>
            <div class={`w-100 d-flex flex-column ${classNames}`} style={styles}>
                {showFilterBox && list.length > 5 && <div class='d-flex flex-row justify-content-center px-3 mb-3'>
                    <div class='input-group'>
                        <div class='input-group-prepend'>
                            <span class='input-group-text rounded-pill-left'>
                                <i class='fa-regular fa-filter'></i>
                            </span>
                        </div>
                        <input
                            class={`form-control ${!search ? 'rounded-pill-right' : ''}`}
                            type='text'
                            value={search}
                            placeholder={`Filter ${title}...`}
                            onInput={this.searchInput}
                        />
                        {search && <div class='input-group-append'>
                            <button class='btn btn-outline-secondary rounded-pill-right' type='button' onClick={this.clearSearch}>
                                <i class='fa-solid fa-circle-xmark'></i>
                            </button>
                        </div>}
                    </div>
                </div>}

                {filter && filter() && <>
                    <div class='d-flex flex-row justify-content-start px-3 mb-3'>
                        <div class='text-muted'>
                            <Text id='list.filtered-by'>Filtered by:</Text>
                        </div>
                        <div class='flex-fill ml-2'>
                            {filter()}
                        </div>
                    </div>
                </>}

                <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between mb-1'>
                    {title}
                    <div>
                        {children}
                        {addItem && <div class='float-right'>
                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill' onClick={addItem}><i class='fa-solid fa-plus text-muted' /> <Text id='input.add'>Add</Text></button>
                        </div>}
                        {sortBy.length > 0 && sortFunc && <div class='float-right'>
                            <button type='button' class='btn btn-sm btn-outline-secondary rounded-pill mr-2' onClick={sortFunc}>
                                {sortDir === 'asc' ? <i class='fas fa-arrow-down-wide-short text-muted' /> : <i class='fas fa-arrow-up-short-wide text-muted' />} {sortByFieldObj ? sortByFieldObj.title : sortByField}
                            </button>
                        </div>}
                        {toggleTitle && toggleFunc && <div class='float-right'>
                            <button type='button' class={`btn btn-sm btn-${toggle ? 'success' : 'outline-secondary'} rounded-pill mr-2`} onClick={toggleFunc}>
                                <i class='fas fa-filter' /> {toggleTitle}
                            </button>
                        </div>}
                        {rightButton && <div class='float-right'>
                            {rightButton}
                        </div>}
                    </div>
                </div>
                <div class={`d-flex flex-column ${classNamesList} rounded-lg ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                    {list.length === 0 && <>
                        <div class='w-100 d-flex flex-column px-4 py-3 box-line'>
                            <div class='w-100 d-flex flex-row justify-content-center'>
                                <div class='box-line-add'>
                                    <i class='fa-solid fa-circle-question' /> {category}
                                </div>
                            </div>
                        </div>
                    </>}

                    <div class='d-flex flex-column position-relative' tabIndex='0' onKeyDown={this.handleKeyDown}>
                        <FastList
                            data={finalList}
                            wrapperClassNames={'w-100 overflow-hidden box-line'}
                            contentClassNames={`w-100 d-flex flex-row justify-content-between `}
                            renderContent={this.renderContent}
                            dataFields={['id', 'type']}
                            dataValues={{ type: holdingStore.name }}
                            handleIntersection={this.handleIntersection}
                        />
                    </div>

                </div>
            </div>
            {help && <>
                <div
                    class={`w-100 d-flex justify-content-center px-3 text-center ${helpTextClassNames}`}
                    style='line-height: 1.0em;'
                >
                    <small class='text-muted'>
                        {help}
                    </small>
                </div>
            </>}
        </>);
    }
}

export default GuiList;
