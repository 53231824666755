import { h, Component } from 'preact';
import util from 'preact-util';
import { observer } from 'mobx-preact';
import { Text, Localizer, withText } from 'preact-i18n';
import { route } from 'preact-router';
import Markdown from 'preact-markdown';
import linkstate from 'linkstate';
import md5 from 'crypto-js/md5';

import localUtil from '../../lib/util';
import fields from '../../lib/fields';

import ViewFields from '../../components/form/viewFields';
import Input from '../../components/form/input';
import DyrejournalSaleSearch from '../../components/dyrejournal/search';

function isValidDate(dateString) {
    // console.log('isValidDate', dateString);

    const date = new Date(dateString);
    // Check if the date is invalid
    if (isNaN(date)) {
        // console.log('isNaN(date)', isNaN(date));
        return false;
    }
    // Check if the year is before 1800
    if (date.getFullYear() < 1900) {
        // console.log('date.getFullYear() < 1900', date.getFullYear() < 1900);
        return false;
    }
    // If we reach here, the date is valid
    return true;
}

@withText(props => ({
    drugTitle: <Text id='dyrejournal.drug.search-title'>Search for drug</Text>,
    retentionDays: <Text id='input.retention-days'>Retantion days</Text>,
}))
@observer
class AddMedicine extends Component {
	constructor(props) {
        super(props);
        this.state = {
            selectedItem: {},
            inputMedicine: {},
            inputRetention: {},
        };
        this.searchTimer = null;
    }

    loadAll = async (props = this.props, emptyFirst = true) => {
        const { drugStore } = this.props.stores;
        // await drugStore.load();
    }

    onClickChooseDrug = e => {
        e.preventDefault();
        e.stopPropagation();
        const { id } = e.target.closest('tr').dataset;
        const { drawerLevel = 1, id: journalId } = this.props;
		const { appState, saleStore, drugStore } = this.props.stores;
        const { newSale } = saleStore;
        const { drugs = [] } = newSale;
        const { drugs: allDrugs } = drugStore;
        const selectedItem = allDrugs.find(d => d.id === parseInt(id, 10));
        if (selectedItem) {
            const { journals = [] } = newSale;
            // Add drug to journal with id journalId
            const journal = journals.find(j => j.id === parseInt(journalId, 10));
            appState.toggleDrawer(false, drawerLevel);
        }
    }

    searchDrugCallback = selectedItem => {
        const finalSelectedItem = JSON.parse(JSON.stringify(selectedItem));
        const inputMedicine = {
            id: finalSelectedItem.id,
            brandId: finalSelectedItem.pakningsinfoRefLegemiddelMerkevare || finalSelectedItem['medisin-merke-id'],
            name: finalSelectedItem.varenavn || finalSelectedItem.navn,
            fullname: finalSelectedItem.navnFormStyrke || finalSelectedItem.navn,
            uuidv4: finalSelectedItem.uuidv4,
            amountPerAnimal: '',
            unit: finalSelectedItem.enhetDoseringDn,
            no: `${finalSelectedItem.pakningsinfoPakningsstr || ''} ${finalSelectedItem.pakningsinfoEnhetPakningV || ''}`,
            lastThreatment: util.isoDate(),
            usageReason: '',
            threatmentBy: '',
            retentionPeriods: finalSelectedItem.tilbakeholdsfrister || [],
            productNumber: finalSelectedItem.varenr,
        };
        this.setState({ selectedItem, inputMedicine });
    }

    addRetentionPeriode = async e => {
        e.preventDefault();
        e.stopPropagation();
        const { selectedItem, inputMedicine, inputRetention } = this.state;
        const { retentionPeriods = [] } = inputMedicine;
        // console.log('addRetentionPeriode', retentionPeriods);
        const newRetention = {
            type: inputRetention.type,
            dyreslag: [
                {
                    dyreslagkode: inputRetention.dyreslag,
                    'antall-dager': inputRetention.days,
                },
            ],
        };
        retentionPeriods.push(newRetention);
        inputMedicine.retentionPeriods = retentionPeriods;
        this.setState({ inputMedicine, inputRetention: {} });

        if (selectedItem && selectedItem.id) {
            const { journalStore } = this.props.stores;
            // journalStore.updateObjectKeyArrFieldValue('journal', 'medicine', selectedItem.id, 'retentionPeriods', retentionPeriods);

            await journalStore.editArrayObject({
                field: 'medicine',
                key: 'retentionPeriods',
                value: retentionPeriods,
                id: selectedItem.id,
                md5: selectedItem.md5,
            });
        }

        {/* "retentionPeriods" : [
                {
                    "dyreslag" : [
                        {
                            "dyreslagkode" : "Storfe",
                            "antall-dager" : 5
                        }
                    ],
                    "type" : "Produkt"
                },
                {
                    "dyreslag" : [
                        {
                            "dyreslagkode" : "Gris",
                            "antall-dager" : 5
                        },
                        {
                            "dyreslagkode" : "Storfe",
                            "antall-dager" : 15
                        }
                    ],
                    "type" : "Slakt"
                }
            ], */}


    }

    toggleAddRetentionPeriode = e => {
        e.preventDefault();
        e.stopPropagation();
        const { showAddRetentionPeriode } = this.state;
        this.setState({ showAddRetentionPeriode: !showAddRetentionPeriode });
    }

    onClickAddDrugToJournal = async () => {
        const { inputMedicine } = this.state;
        const { saleStore, journalStore } = this.props.stores;
        const { id: jId, saleLoadJournals = () => {}, object, callback = () => {} } = this.props;
        const { newSale } = saleStore;
        const { journals = [] } = newSale;
        const { journals: fallbackJournals } = journalStore;
        const finalJournals = journals.length > 0 ? journals : fallbackJournals;

        const journalId = parseInt(jId || object.id, 10);
        const journal = finalJournals.find(j => j.id === journalId);

        console.log('onClickAddDrugToJournal', journal)

        const value = {
            ...inputMedicine,
            md5: md5(JSON.stringify({ ...inputMedicine, date: new Date() })).toString(),
            date: new Date(),
        };
        if (journal?.id) {
            await journalStore.saveField(journal.id, 'medicine', value);
            await saleLoadJournals();
        }
        callback();
        this.setState({ inputMedicine: {}, inputRetention: {} });
    }

    onClickDeleteDrugFromJournal = async e => {
        const { md5 } = e.target.closest('button').dataset;
        const { saleStore, journalStore } = this.props.stores;
        const { id: jId, saleLoadJournals = () => {}, object, callback = () => {} } = this.props;
        const { newSale } = saleStore;
        const { journals = [] } = newSale;
        const { journals: fallbackJournals } = journalStore;
        const finalJournals = journals.length > 0 ? journals : fallbackJournals;

        const journalId = parseInt(jId || object.id, 10);
        const journal = finalJournals.find(j => j.id === journalId);

        await journalStore.removeArrayObject({ field: 'medicine', id: journalId, md5 });
        callback();
        await saleLoadJournals();
    }

    onClickEditLine = e => {
        const { md5 } = e.target.closest('tr').dataset;
        const { journalStore, saleStore } = this.props.stores;
        const { id: jId } = this.props;

        const { animalJournals } = saleStore;
        const { journals: fallbackJournals } = journalStore;
        const finalJournals = animalJournals.length > 0 ? animalJournals : fallbackJournals;

        const journalId = parseInt(jId || object.id, 10);
        const journal = finalJournals.find(j => j.id === journalId) || {};

        const { medicine = [] } = journal;
        const foundMedicine = medicine.find(m => m.md5 === md5);
        const selectedItem = JSON.parse(JSON.stringify(foundMedicine));
        this.setState({ selectedItem, inputMedicine: { ...selectedItem } });

        journalStore.updateKeyValue('journal', journal);
    }

    onInput = e => {
        const { inputMedicine } = this.state;
        const { journalStore, saleStore } = this.props.stores;
        const { name, value, type } = e.target;
        const { md5 } = e.target.closest('input, select').dataset;

        const { journal } = journalStore;
        const idx = journal.medicine.findIndex(m => m.md5 === md5);

        inputMedicine[name] = value;
        this.setState({ inputMedicine });

        if (type === 'datetime-local' && !isValidDate(value)) {
            return false;
        }
        if (type === 'date' && !isValidDate(value)) {
            return false;
        }

        // updateObjectKeyArrFieldValue(obj, key, idx, field, value) {
        journalStore.updateObjectKeyArrFieldValue('journal', 'medicine', idx, name, value);

        clearTimeout(this.updateTimer);
        this.updateTimer = setTimeout(() => {
            this.doUpdate(e);
        }, 1000);
    }

    doUpdate = async (e) => {
        const { id: journalId } = this.props;
        const { journalStore } = this.props.stores;
        const { name, value } = e.target;
        const { md5 } = e.target.closest('input, select').dataset;
        await journalStore.editArrayObject({
            field: 'medicine',
            key: name,
            value,
            id: journalId,
            md5,
        });
    }

    onClickEditDone = async () => {
        const { journalStore } = this.props.stores;
        this.setState({ selectedItem: null, inputMedicine: {} });
        journalStore.updateKeyValue('journal', {});
    }

    displayRetentionPeriods = retentionPeriods => {
        if (!retentionPeriods) {
            return '';
        }
        return retentionPeriods.map(retention => {
            return(<>
                <div class='d-flex flex-row'>
                    <div class='mr-1 font-weight-bold'>{retention.type}</div>
                    <div class='d-flex flex-row'>
                        {retention.dyreslag.map((dyreslag, idx) => {
                            return(<>
                                <div class='mr-1 text-muted'>{dyreslag.dyreslagkode}</div>
                                <div class='mr-1'>{dyreslag['antall-dager']}</div>
                            </>);
                        })}
                    </div>
                </div>
            </>);
        });
    }

    displayUsageReason = usageReason => {
        switch (usageReason) {
            case '1':
                return '1: Andre';
            case '2':
                return '2: Forebyggende';
            case '3':
                return '3: Sykdom';
            default:
                return '';
        }
    }

    displayThreatmentBy = threatmentBy => {
        switch (threatmentBy) {
            case '1':
                return '1: Dyreeier/medhjelper';
            case '2':
                return '2: Dyrehelsepersonell';
            default:
                return '';
        }
    }

    componentDidMount() {
        this.loadAll();
    }

    render() {
        const { selectedItem, inputMedicine, inputRetention, showAddRetentionPeriode } = this.state;
        const {
            retentionDays,
            drugTitle,
            id: jId,
            object,
        } = this.props;
        const { userStore, drugStore, saleStore, journalStore } = this.props.stores;
        const { user, isAdmin } = userStore;
        const darkmode= util.getNestedValue(user, 'settings.darkmode');

        const { newSale, animalJournals } = saleStore;
        const { journals: fallbackJournals } = journalStore;
        const finalJournals = animalJournals.length > 0 ? animalJournals : fallbackJournals;

        const journalId = parseInt(jId || object.id, 10);
        const journal = finalJournals.find(j => j.id === journalId) || {};

        const { medicine = [] } = journal;

        return (
            <div class='w-100 d-flex justify-content-center'>
                <div class='w-100 h-100' style='padding-top: 20px; padding-bottom: 100px;'>
{/* <xmp>{JSON.stringify(inputMedicine, null, 2)}</xmp> */}
{/* <xmp>{JSON.stringify(inputRetention, null, 2)}</xmp> */}
                    <div class='w-100 d-flex flex-sm-row flex-column'>
                        <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.add-medicine'>Add medicine</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                <DyrejournalSaleSearch
                                    stores={this.props.stores}
                                    key={'drugs'}
                                    keyName={'drugs'}
                                    title={drugTitle}
                                    holdingStore={drugStore}
                                    searchResultList={'searchResults'}
                                    searchResultsLine={(e, idx, selectedIndex) => {
                                        return(<>
                                            <div class={`d-flex flex-row justify-content-between px-3 py-1 ${idx > 0 ? 'border-top' : ''} ${idx === selectedIndex ? 'bg-primary-light' : ''}`}>
                                                <div>
                                                    {e.navnFormStyrke || e.navn} ({e.pakningsinfoMengde} {e.pakningsinfoEnhetPakningV} {e.preparattype})
                                                </div>
                                                <span class='font-weight-lighter text-muted'>{this.displayRetentionPeriods(e.tilbakeholdsfrister)}</span>

                                            </div>
                                        </>);
                                    }}
                                    callback={this.searchDrugCallback}
                                />
                                <div class={`table-responsive ${darkmode ? 'bg-darkmode' : 'bg-lightmode'} mt-3`}>
                                    <table class='table table-striped table-sm mb-0'>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th><Text id='dyrejournal.medicine'>Medicine</Text></th>
                                                <th><Text id='dyrejournal.medicine-amount-per-animal'>Amount (each animal)</Text></th>
                                                <th><Text id='dyrejournal.medicine-unit'>Unit</Text></th>
                                                <th><Text id='dyrejournal.medicine-no'>No.</Text></th>
                                                <th><Text id='dyrejournal.medicine-last-threatment'>Last threatment</Text></th>
                                                <th><Text id='dyrejournal.medicine-usage-reason'>Usage reason</Text></th>
                                                <th><Text id='dyrejournal.medicine-threatment-by'>Threatment by</Text></th>
                                                <th><Text id='dyrejournal.medicine-retention-periods'>Retention periods</Text></th>
                                                <th><Text id='dyrejournal.medicine-product-number'>Prod num</Text></th>
                                                <th>&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medicine && medicine.length > 0 && medicine.map((e, idx) => {
                                                return(<>
                                                    <tr data-md5={e.md5}>
                                                        <td onClick={this.onClickEditLine}>{e.md5 === inputMedicine.md5 ? <i class='fa-duotone fa-circle fa-bounce' /> : <i class='fa-duotone fa-pen' />}</td>
                                                        <td>{e.fullname || e.name}</td>
                                                        <td>{e.amountPerAnimal}</td>
                                                        <td>{e.unit}</td>
                                                        <td>{e.no}</td>
                                                        <td>{e.lastThreatment}</td>
                                                        <td>{this.displayUsageReason(e.usageReason)}</td>
                                                        <td>{this.displayThreatmentBy(e.threatmentBy)}</td>
                                                        <td>{this.displayRetentionPeriods(e.retentionPeriods)}</td>
                                                        <td>{e.productNumber}</td>
                                                        <td>
                                                            <button
                                                                class='btn btn-danger btn-sm py-1 rounded-pill'
                                                                onClick={this.onClickDeleteDrugFromJournal}
                                                                data-md5={e.md5}
                                                            >
                                                                <nobr>
                                                                    <i class='fa-solid fa-trash mr-1' />
                                                                    <Text id='input.delete'>Delete</Text>
                                                                </nobr>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </>);
                                            })}

                                            {selectedItem && selectedItem.id && <>
                                                {inputMedicine.md5 ? <>
                                                    <tr class='bg-secondary'>
                                                        <th colspan='11'><Text id='dyrejournal.medicine-edit'>Editing medicine</Text></th>
                                                    </tr>
                                                    <tr class='bg-secondary'>
                                                        <th>&nbsp;</th>
                                                        <td><small>{inputMedicine.name}</small></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='amountPerAnimal' value={inputMedicine.amountPerAnimal} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='unit' value={inputMedicine.unit} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='no' value={inputMedicine.no} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td><input type='datetime-local' class='form-control form-control-sm' name='lastThreatment' value={inputMedicine.lastThreatment} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td>
                                                            <select class='form-control form-control-sm' name='usageReason' value={inputMedicine.usageReason} data-md5={selectedItem.md5} onInput={this.onInput}>
                                                                <option value='1'>1: Andre</option>
                                                                <option value='2'>2: Forebyggende</option>
                                                                <option value='3'>3: Sykdom</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class='form-control form-control-sm' name='threatmentBy' value={inputMedicine.threatmentBy} data-md5={selectedItem.md5} onInput={this.onInput}>
                                                                <option value='1'>1: Dyreeier/medhjelper</option>
                                                                <option value='2'>2: Dyrehelsepersonell</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <small>{this.displayRetentionPeriods(inputMedicine.retentionPeriods)}</small>
                                                            {/* {showAddRetentionPeriode ? <>
                                                                <div class='d-flex flex-column'>
                                                                    <select class='form-control form-control-sm' name='retentionType' value={inputRetention.type} onInput={linkstate(this, 'inputRetention.type')}>
                                                                        <option value=''>-- <Text id='input.select-retention-type'>Select retention type</Text></option>
                                                                        <option value='Produkt'>Produkt</option>
                                                                        <option value='Slakt'>Slakt</option>
                                                                    </select>
                                                                    <select class='form-control form-control-sm' name='retentionDyreslag' value={inputRetention.dyreslag} onInput={linkstate(this, 'inputRetention.dyreslag')}>
                                                                        <option value=''><Text id='input.select-animal-kind'>Select dyreslag</Text></option>
                                                                        <option value='Storfe'>Storfe</option>
                                                                        <option value='Geit'>Geit</option>
                                                                        <option value='Gris'>Gris</option>
                                                                        <option value='Hest'>Hest</option>
                                                                        <option value='Sau'>Sau</option>
                                                                    </select>
                                                                    <input type='number' class='form-control form-control-sm' name='retentionDays' value={inputRetention.days} onInput={linkstate(this, 'inputRetention.days')} placeholder={retentionDays} />

                                                                    <button class='btn btn-primary btn-sm p-0' onClick={this.addRetentionPeriode}>
                                                                        <i class='fa-duotone fa-plus' /> <Text id='input.add'>Add</Text>
                                                                    </button>
                                                                </div>
                                                            </> : <>
                                                                <div class='d-flex flex-column'>
                                                                    <button class='btn btn-link btn-sm p-0' onClick={this.toggleAddRetentionPeriode}>
                                                                        <i class='fa-duotone fa-plus' />
                                                                    </button>
                                                                </div>
                                                            </>} */}

                                                        </td>
                                                        <td><input type='text' class='form-control form-control-sm' name='productNumber' value={inputMedicine.productNumber} data-md5={selectedItem.md5} onInput={this.onInput} /></td>
                                                        <td>
                                                            <button class='btn btn-primary btn-sm py-1 rounded-pill' onClick={this.onClickEditDone}>
                                                                <nobr>
                                                                    <i class='fa-solid fa-floppy-disk mr-1' />
                                                                    <Text id='input.done'>Done</Text>
                                                                </nobr>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </> : <>
                                                    <tr>
                                                        <th>&nbsp;</th>
                                                        <td><small>{selectedItem.navnFormStyrke || selectedItem.navn}</small></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='amountPerAnimal' value={inputMedicine.amountPerAnimal} onInput={linkstate(this, 'inputMedicine.amountPerAnimal')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='unit' value={inputMedicine.unit} onInput={linkstate(this, 'inputMedicine.unit')} /></td>
                                                        <td><input type='text' class='form-control form-control-sm' name='no' value={inputMedicine.no || ''} onInput={linkstate(this, 'inputMedicine.no')} /></td>
                                                        <td><input type='datetime-local' class='form-control form-control-sm' name='lastThreatment' value={inputMedicine.lastThreatment} onInput={linkstate(this, 'inputMedicine.lastThreatment')} /></td>
                                                        <td>
                                                            <select class='form-control form-control-sm' name='usageReason' value={inputMedicine.usageReason} data-md5={selectedItem.md5} onInput={linkstate(this, 'inputMedicine.usageReason')}>
                                                                <option value='1'>1: Andre</option>
                                                                <option value='2'>2: Forebyggende</option>
                                                                <option value='3'>3: Sykdom</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select class='form-control form-control-sm' name='threatmentBy' value={inputMedicine.threatmentBy} data-md5={selectedItem.md5}  onInput={linkstate(this, 'inputMedicine.threatmentBy')}>
                                                                <option value='1'>1: Dyreeier/medhjelper</option>
                                                                <option value='2'>2: Dyrehelsepersonell</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <small>
                                                                {this.displayRetentionPeriods(inputMedicine.retentionPeriods)}
                                                            </small>
                                                            {showAddRetentionPeriode ? <>
                                                                <div class='d-flex flex-column'>
                                                                    <select class='form-control form-control-sm' name='retentionType' value={inputRetention.type} onInput={linkstate(this, 'inputRetention.type')}>
                                                                        <option value=''>-- <Text id='input.select-retention-type'>Select retention type</Text></option>
                                                                        <option value='Produkt'>Produkt</option>
                                                                        <option value='Slakt'>Slakt</option>
                                                                    </select>
                                                                    <select class='form-control form-control-sm' name='retentionDyreslag' value={inputRetention.dyreslag} onInput={linkstate(this, 'inputRetention.dyreslag')}>
                                                                        <option value=''><Text id='input.select-animal-kind'>Select dyreslag</Text></option>
                                                                        <option value='Storfe'>Storfe</option>
                                                                        <option value='Geit'>Geit</option>
                                                                        <option value='Gris'>Gris</option>
                                                                        <option value='Hest'>Hest</option>
                                                                        <option value='Sau'>Sau</option>
                                                                    </select>
                                                                    <input type='number' class='form-control form-control-sm' name='retentionDays' value={inputRetention.days} onInput={linkstate(this, 'inputRetention.days')} placeholder={retentionDays} />

                                                                    <button class='btn btn-primary btn-sm p-0' onClick={this.addRetentionPeriode}>
                                                                        <i class='fa-duotone fa-plus' /> <Text id='input.add'>Add</Text>
                                                                    </button>
                                                                </div>
                                                            </> : <>
                                                                <div class='d-flex flex-column'>
                                                                    <button class='btn btn-link btn-sm p-0' onClick={this.toggleAddRetentionPeriode}>
                                                                        <i class='fa-duotone fa-plus' />
                                                                    </button>
                                                                </div>
                                                            </>}
                                                        </td>
                                                        <td><input type='text' class='form-control form-control-sm' name='productNumber' value={inputMedicine.productNumber} onInput={linkstate(this, 'inputMedicine.productNumber')} /></td>
                                                        <td>
                                                            <nobr>
                                                                <button class='btn btn-primary btn-sm py-1 rounded-pill' onClick={this.onClickAddDrugToJournal}>
                                                                    <i class='fa-solid fa-plus mr-1' />
                                                                    <Text id='input.add'>Add</Text>
                                                                </button>
                                                            </nobr>
                                                        </td>
                                                    </tr>
                                                </>}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                                {/* <xmp>{JSON.stringify(selectedItem, null, 2)}</xmp> */}

                            </div>
                        </div>
                        {/* <div class='w-100 d-flex flex-column'>
                            <div class='font-weight-lighter px-3 box-header d-flex flex-row justify-content-between'>
                                <Text id='dyrejournal.report.journals'>Journals</Text>
                            </div>
                            <div class={`d-flex flex-column mx-3 px-4 py-3 box-container ${darkmode ? 'bg-darkmode' : 'bg-lightmode'}`}>
                                Report for today's journals
                            </div>
                        </div> */}
                    </div>

                </div>
            </div>
        );
    }
}

export default AddMedicine;
